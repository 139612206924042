import React from 'react'
import { Row } from 'antd';

export default function Footer() {
	return (
		<footer className="footer">
			{/* <span>Copyright  &copy;  {`${new Date().getFullYear()}`} <span className="font-weight-semibold">{`${APP_NAME}`}</span> All rights reserved.</span>
			<div>
				<a className="text-gray" href="/#" onClick={e => e.preventDefault()}>Term asas& Conditions</a>
				<span className="mx-2 text-muted"> | </span>
				<a className="text-gray" href="/#" onClick={e => e.preventDefault()}>Privacy & Policy</a>
			</div> */}

			<Row>

			<span className="credits-text">DEVELOPED BY</span>
				<a href="https://www.pi-interactiva.com/hi" target="_blank">
					<div className="animation-wrap">
						<div className="pi">
							<div className="adelante pi1"></div>
							<div className="atras">
								<img src="https://www.pi-interactiva.com/wp-content/uploads/2022/05/Pi-Interactiva.png" alt="PI Interactiva" />
							</div>
						</div>
					</div>
				</a>
			</Row>
		</footer>
	)
}

