import React from "react";
import { Menu, Dropdown, Avatar, message } from "antd";
import { connect } from 'react-redux'
import { 
  LogoutOutlined, 
  CheckSquareOutlined,
  DesktopOutlined,
} from '@ant-design/icons';
import { signOut } from 'redux/actions/Auth';
import baseUrl from "utils/baseUrl";
import axios from "axios";

export const NavProfile = ({signOut}) => {

  const pausarTareas = async () =>{
    try {
      const url = `${baseUrl}/api/processinstance/espera`;
        await axios.post(url,{},{      
          headers: {
              'x-token': localStorage.getItem('x-token')
          }
        })
      message.success({ content: 'Las tareas han sido pausadas', duration: 5,
        style: { marginTop: '60px' },
      });
    } catch (error) {
      message.error({ content: 'No se pudo pausar las tareas ', duration: 5,
        style: { marginTop: '60px' },
      });
    }
  }
  const despausarTareas = async () =>{
    try {
      const url = `${baseUrl}/api/processinstance/despausa`;
      await axios.post(url, {}, {      
        headers: {
          'x-token': localStorage.getItem('x-token')
        }
      })
      message.success({ content: 'Las tareas han sido despausadas', duration: 5,
        style: { marginTop: '60px' },
      });
    } catch (error) {
      message.error({ content: 'No se pudo despausar las tareas ', duration: 5,
      style: { marginTop: '60px' },
    });
    }
  }

  const profileImg = "/img/p.png";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">Cyfral</h4>
            <span className="text-muted">Usuario</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {
            localStorage.getItem('user') === 'super_admin_role' ?
            <>
              <Menu.Item key={1} onClick={ () => pausarTareas()}>
                <span>
                  <LogoutOutlined />
                  <span className="font-weight-normal">Pausar tareas</span>
                </span>
              </Menu.Item>

              <Menu.Item key={2} onClick={ () => despausarTareas()}>
                <span>
                <CheckSquareOutlined />
                  <span className="font-weight-normal">Despausar tareas</span>
                </span>
              </Menu.Item>
            </>  
            :
            localStorage.getItem('user') === 'estacion_role'?
            <>
              <Menu.Item key={10101010}>
                <span>
                  <DesktopOutlined />
                  <span className="font-weight-normal"> Estación # {localStorage.getItem('station_number')}</span>
                </span>
              </Menu.Item> 
            </>
            :null
          }
          
          <Menu.Item key={4} onClick={e => signOut()}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Cerrar Sesión</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default connect(null, {signOut})(NavProfile)
